<template>
    <div>
        <v-dialog v-model="$store.state.dialog" width="800">
            <v-card flat class="card">
                <v-toolbar outlined flat color="grey lighten-4">
                    <v-toolbar-title>
                        {{
                        $store.state.itemDetails.dialogType == "add"
                        ? "إضافة"
                        : "تعديل"
                        }}
                        {{ this.$route.meta.single }}
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn @click="close()" icon>
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
                    <v-container>
                        <v-card outlined>
                            <v-container>
                                <v-row>
                                    <!-- titel -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2">
                                            عنوان الخبر
                                            <span class="required">*</span>
                                        </h4>
                                        <v-text-field
                                            v-model="item.title"
                                            placeholder="تاسيس المطبعة"
                                            color="info"
                                            :rules="[$global.state.required()]"
                                            required
                                            filled
                                            outlined
                                            hide-details="auto"
                                        />
                                    </v-col>

                                    <!-- typeId -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2">
                                            النوع
                                            <span class="required">*</span>
                                        </h4>
                                        <v-autocomplete
                                            clearable
                                            v-model="item.newsTypeId"
                                            item-text="name"
                                            item-value="id"
                                            :items="$global.state.types"
                                            placeholder="مناسبة تاسيس"
                                            :loading="$global.state.loading"
                                            no-data-text="لا توجد بيانات"
                                            hide-details
                                            color="info"
                                            prepend-inner-icon="place"
                                            outlined
                                            filled
                                        />
                                    </v-col>

                                    <!-- description -->
                                    <v-col cols="12" md="12">
                                        <h4 class="mb-2">الوصف</h4>
                                        <v-textarea
                                            v-model="item.body"
                                            required
                                            outlined
                                            filled
                                            hide-details="auto"
                                            placeholder="في مثل هذا اليوم تاسست مطبعة شمس الغري"
                                            color="info"
                                        ></v-textarea>
                                    </v-col>

                                    <!-- attachments -->
                                    <v-col cols="12" md="12">
                                        <h4 class="mb-2">المرفقات</h4>
                                        <MultipartFileUpload
                                            accept="image/*;capture=camera;video/mp4,video/x-m4v,video/*"
                                            formData="files"
                                            endPoint="UploadFile/postUploadFiles"
                                            buttonText="رفع صـور"
                                            icon="true"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                        <!-- actions -->
                        <v-col cols="12" md="12">
                            <v-row class="mt-2">
                                <v-btn
                                    type="submit"
                                    :loading="$global.state.loading"
                                    x-large
                                    color="primary secondary--text"
                                    class="btn"
                                    :disabled="!valid"
                                >حفظ</v-btn>
                                <v-btn
                                    @click="close()"
                                    x-large
                                    color="error"
                                    class="mr-2 btn"
                                    outlined
                                >إلغاء</v-btn>
                            </v-row>
                        </v-col>
                    </v-container>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    components: {
        MultipartFileUpload: () =>
            import("@/components/MultipartFileUpload.vue"),
    },

    data() {
        return {
            item: {},
            valid: false,
            menuexpiryDate: false,
            expiryDate: new Date().toISOString().split("T")[0],
        };
    },

    mounted() {
        this.reset();
        this.$eventBus.$on("fill-fields", () => {
            this.item = this.$store.state.itemDetails;
            this.item.newsTypeId = this.$store.state.itemDetails.newsType.id;

            if (this.$store.state.itemDetails.attachments) {
                this.$store.state.itemDetails.attachments.forEach((element) => {
                    this.$store.state.previews.push(element.path);
                });
            }
        });
    },

    methods: {
        reset() {
            this.item = {
                title: "",
                body: "",
                newsTypeId: "",
                attachments: [],
            };
            this.$store.commit("clearFiles");
        },

        submit() {
            this.$refs.form.validate();
            if (this.$refs.form.validate()) {
                if (this.$store.state.itemDetails.dialogType == "add") {
                    this.addItem();
                } else {
                    this.editItem();
                }
            }
        },

        async addItem() {
            this.$global.state.loading = true;
            this.item.expiryDate = this.expiryDate;

            // upload files
            if (this.$store.state.files[0] != null) {
                let formData = new FormData();
                for (const i of Object.keys(this.$store.state.files)) {
                    formData.append("files", this.$store.state.files[i]);
                }
                await this.$http
                    .post("/UploadFile/postUploadFiles", formData)
                    .then((res) => {
                        res.data.result.fileList.forEach((element) => {
                            this.item.attachments.push({
                                path: element.path,
                                name: element.name,
                                type: element.type,
                                isMain: false,
                            });
                        });
                    });
            }
            // this.$store.state.files.forEach(element => {
            //     if(element.type.split("/")[0] === "video")
            // });

            this.item.attachments[0].isMain = true;
            await this.$http
                .post(`${this.$route.meta.endPoint}`, this.item)
                .then((res) => {
                    this.$eventBus.$emit(`refresh`);
                    this.close();
                    this.$store.dispatch("toastification/setToast", {
                        message: `${res.data.message}`,
                        type: "success",
                    });
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        async editItem() {
            this.$global.state.loading = true;
            this.item.expiryDate = this.expiryDate;

            // upload new files
            this.$global.state.loading = true;
            if (this.$store.state.files[0] != null) {
                let formData = new FormData();
                for (const i of Object.keys(this.$store.state.files)) {
                    formData.append("files", this.$store.state.files[i]);
                }
                await this.$http
                    .post("/UploadFile/postUploadFiles", formData)
                    .then((res) => {
                        res.data.result.fileList.forEach((element) => {
                            this.item.attachments.push({
                                path: element.path,
                                name: element.name,
                                type: element.type,
                            });
                        });
                    });
            }

            this.item.attachments.forEach((element) => {
                element.path = element.path.replace(this.$store.state.url, "");
            });

            await this.$http
                .put(`${this.$route.meta.endPoint}/${this.item.id}`, this.item)
                .then((res) => {
                    this.close();
                    this.$store.dispatch("toastification/setToast", {
                        message: `${res.data.message}`,
                        type: "success",
                    });
                    this.$eventBus.$emit(`refresh`);
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        close() {
            this.$store.commit("setDialog");
            this.resetValidation();
            this.$store.commit("setItemDetails", {});
            this.reset();
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style scoped>
.btn-delete-file {
    padding: 11px;
    font-weight: bold;
}
</style>
